import logoNekrasovka from "./logo_nekrasovka";
import arrowDown from "./arrow_down";
import arrowRight from "./arrow_right";
import arrowLineRight from "./arrow_line_right";
import arrowLineLeft from "./arrow_line_left";
import yandexMail from "./yandex_mail";
import yandexDisk from "./yandex_disk";
import yandexCalendar from "./yandex_calendar";
import calendarPlaner from "./calendar_planer";
import openModal from "./open_modal";
import closeModal from "./close_modal";
import home from "./home";
import exit from "./exit";
import filtersList from "./filters_list";
import filtersMozaic from "./filters_mozaic";
import telegram from "./telegram";
import search from "./search";
import menu from "./menu";
import closeMenu from "./close_menu";
import jira10001 from "./jira_epic";
import jira10006 from "./jira_error";
import floors from "./floors";
import xlsx from "./xlsx";
import eye from "./eye";
import eyeClosed from "./eye_closed";
import copy from "./copy";

export default {
  openModal,
  closeModal,
  logoNekrasovka,
  arrowDown,
  yandexMail,
  yandexDisk,
  yandexCalendar,
  home,
  exit,
  arrowRight,
  arrowLineRight,
  arrowLineLeft,
  filtersList,
  filtersMozaic,
  calendarPlaner,
  telegram,
  search,
  menu,
  closeMenu,
  jira10001,
  jira10006,
  floors,
  xlsx,
  eye,
  eyeClosed,
  copy,
};
