import React from "react";
import styled from "styled-components";
import ButtonsGroup from "../../../Dedusting/nekrasovka-ui/ButtonsGroup";
import ContentModal from "../../../Dedusting/nekrasovka-ui/ContentModal";

const Form = ({
  signature,
  from_name,
  handleInputChange,
  isPhone,
  setIsPhone,
  isCountry,
  setIsCountry,
  countryCodeId,
  phones,
  countryCodes,
  handlePhone,
  handleCountry,
}) => {
  return (
    <Container>
      <label htmlFor="fullname">Полное имя</label>
      <Input
        type="text"
        id="fullname"
        name="fullname"
        value={signature.fullname}
        placeholder={from_name}
        onChange={handleInputChange}
      />
      <label htmlFor="title">Название должности</label>
      <Input
        type="text"
        id="title"
        name="title"
        value={signature.title}
        onChange={handleInputChange}
      />
      {/*TODO: Добавить выпадающий список отделов. По дефолту выбран отдел из профиля*/}
      <label htmlFor="department">Название отдела</label>
      <Input
        type="text"
        id="department"
        name="department"
        value={signature.department}
        onChange={handleInputChange}
      />
      <label htmlFor="phone">Основной телефон</label>
      <InputPhone>
        <div onClick={() => setIsPhone(!isPhone)}>
          <span>{signature.phone}</span>
        </div>
        <Modal isContentModal={isPhone} setContentModal={setIsPhone}>
          <ModalButtonsGroup
            groupName="phone"
            buttons={phones}
            activeButton={phones.indexOf(signature.phone)}
            handleButton={handlePhone}
          />
        </Modal>
      </InputPhone>
      <label htmlFor="internal_phone">Внутренний телефон</label>
      <Input
        type="text"
        id="internal_phone"
        name="internal_phone"
        value={signature.internal_phone}
        placeholder="XXX"
        onChange={handleInputChange}
      />
      <WithCountryCodes>
        <label>Дополнительный телефон</label>
        <div>
          <div>
            <label htmlFor="country">Страна</label>
            <InputPhone>
              <div onClick={() => setIsCountry(!isCountry)}>
                <span>
                  {countryCodes[countryCodeId].country} (
                  {countryCodes[countryCodeId].code})
                </span>
              </div>
              <Modal isContentModal={isCountry} setContentModal={setIsCountry}>
                <ModalButtonsGroup
                  groupName="country"
                  buttons={countryCodes.map((item) => item.country)}
                  activeButton={countryCodeId}
                  handleButton={handleCountry}
                />
              </Modal>
            </InputPhone>
          </div>
          <div>
            <label htmlFor="alternative_phone">
              Номер телефона (без кода страны)
            </label>
            <Input
              type="text"
              id="alternative_phone"
              name="alternative_phone"
              value={signature.alternative_phone}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </WithCountryCodes>
      <label htmlFor="alternative_email">Альтернативный e-mail</label>
      <Input
        type="email"
        id="alternative_email"
        name="alternative_email"
        value={signature.alternative_email}
        placeholder="e-mail@mail.ru"
        onChange={handleInputChange}
      />
      <label htmlFor="telegram">Имя пользователя в Телеграм</label>
      <Input
        type="text"
        id="telegram"
        name="telegram"
        value={signature.telegram}
        placeholder="допускаюся латинские буквы, цифры и нижнее подчёркивание"
        onChange={handleInputChange}
      />
    </Container>
  );
};

export default Form;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  label {
    font-size: 14px;
    color: rgb(119, 119, 119);
  }
`;

const Input = styled.input`
  width: 100%;
  font-size: 14px;
  padding: 10px 18px;

  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin: 0;
  color: #222222;
  background-color: #ffffff;
`;

const InputPhone = styled.div`
  position: relative;
  cursor: pointer;

  > div {
    :nth-child(1) {
      font-size: 14px;
      padding: 10px 18px;
      height: 38px;
      line-height: 20px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      margin: 0;
      color: #222222;
      background-color: #ffffff;

      span {
        white-space: nowrap;
      }
    }

    :nth-child(2) {
      width: fit-content;

      > div {
        width: auto;
      }

      button {
        font-size: 14px;
        padding: 10px 18px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ModalButtonsGroup = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const Modal = styled(ContentModal)`
  left: 0;
  right: 0;
  margin-top: 5px;

  > div {
    ::after {
      right: 0;
      left: 15px;
    }
  }
`;

const WithCountryCodes = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > div {
    display: flex;
    column-gap: 10px;

    label {
      margin-left: 18px;
    }

    > div {
      :nth-child(1) {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      :nth-child(2) {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 100%;
      }
    }
  }
`;
