import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  font-size: 14px;

  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin: 10px 0 0;
  color: #222222;
  background-color: #ffffff;

  @media (min-width: 701px) {
    padding: 10px 18px;
  }

  @media (max-width: 700px) {
    padding: 10px 15px;
  }
`;

const OwaSecuredInput = styled(Input)`
  ::placeholder {
    -webkit-text-security: ${({ isPasswordVisible }) =>
      isPasswordVisible ? "none" : "disc"};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 701px) {
    padding: 0 30px;
  }
`;

const MailTitle = styled.div`
  display: flex;
  margin-bottom: 20px;
  column-gap: 20px;

  > div {
    cursor: pointer;

    :nth-child(1) {
      ${({ mailType }) =>
        mailType === 1 ? "font-weight: 500;" : "color: #777777;"}
    }

    :nth-child(2) {
      ${({ mailType }) =>
        mailType === 2 ? "font-weight: 500;" : "color: #777777;"}
    }
  }

  @media (hover: hover) {
    > div:hover {
      color: #222222;
    }
  }

  @media (max-width: 700px) {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
  }
`;

const SignatureForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  > div:nth-child(2) > div:nth-child(2) {
    margin-top: 20px;
  }

  @media (min-width: 701px) {
    > div {
      :nth-child(1) {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }

      :nth-child(2) {
        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    padding: 0 15px;

    > div {
      :nth-child(1) {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        button {
          width: 100%;
        }
      }

      :nth-child(2) {
        > div {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }
      }
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  line-height: 19px;
  font-size: 16px;
`;

const SignatureDescription = styled.span`
  font-size: 14px;
  color: rgb(119, 119, 119);
`;

const OwaCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  position: relative;

  label {
    font-size: 14px;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 25px;
    cursor: pointer;
    margin: 0;

    :checked ~ span {
      background: #777777;

      ::after {
        transform: translateX(25px);
      }
    }
  }

  span {
    width: 50px;
    height: 25px;
    border-radius: 20px;
    background: #ccc;
    position: relative;
    transition: background 0.3s ease;

    ::after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: white;
      position: absolute;
      top: 3px;
      left: 3px;
      transition: transform 0.3s ease;
    }
  }

  @media (min-width: 701px) {
    input {
      right: 0;
    }
  }

  @media (max-width: 700px) {
    label {
      max-width: 60%;
    }

    input {
      right: 15px;
    }
  }
`;

const OwaSettings = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: 701px) {
    > div {
      :nth-child(1) {
        pointer-events: none;
      }
    }
  }

  @media (max-width: 700px) {
    padding: 0 15px;

    > div {
      :nth-child(1) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

const OwaHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (max-width: 700px) {
    padding: 0 15px;

    button {
      width: 100%;
    }
  }
`;

const OwaMail = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > div {
    :nth-child(1) {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      > div {
        :nth-child(1) {
          display: flex;
          column-gap: 10px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      :nth-child(1) {
        > div {
          :nth-child(1) {
            padding: 0 15px;
          }
        }
      }
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const OwaSettingsIconButton = styled(IconButton)`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    margin-left: auto;
    transition: transform 0.3s ease;
    ${({ isToggled }) => isToggled && "transform: rotate(180deg);"};
  }
`;

const OwaMailStats = styled.div`
  display: flex;
  column-gap: 10px;

  > div {
    display: flex;
    column-gap: 5px;

    :nth-child(2) {
      color: #777777;
    }

    span {
      cursor: pointer;

      :nth-child(1) {
        display: flex;
        align-items: center;
      }

      :nth-child(2) {
        font-size: 11px;
        position: relative;
      }
    }
  }
`;

const OwaMain = styled.div`
  @media (min-width: 701px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
`;

const OwaForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const OwaAuth = styled.div`
  display: flex;

  label {
    font-size: 14px;
    color: rgb(119, 119, 119);
  }

  > div {
    width: 100%;
    position: relative;

    > div {
      display: flex;
      position: absolute;
      right: 18px;
      bottom: 8px;
    }
  }

  @media (min-width: 701px) {
    column-gap: 15px;

    > div {
      > div {
        column-gap: 20px;
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    row-gap: 15px;

    > div {
      > div {
        column-gap: 30px;
      }
    }
  }
`;

const OwaMailData = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > div {
    :nth-child(1) {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      > div {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        row-gap: 10px;
        border-radius: 5px;

        > div {
          span {
            color: #777777;
          }

          :nth-child(1) {
            display: flex;
            justify-content: space-between;
            column-gap: 20px;

            span {
              :nth-child(2) {
                font-size: 11px;
              }
            }
          }

          :nth-child(2) {
            span {
              :nth-child(1) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 701px) {
    > div {
      :nth-child(1) {
        > div {
          padding: 10px 18px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    padding: 0 15px;

    > div {
      :nth-child(1) {
        > div {
          padding: 10px 15px;
        }
      }
    }
  }
`;

export {
  Container,
  MailTitle,
  SignatureForm,
  Title,
  SignatureDescription,
  Input,
  OwaForm,
  OwaAuth,
  OwaMail,
  OwaSettings,
  OwaCheckbox,
  OwaHeader,
  OwaMain,
  OwaMailStats,
  OwaMailData,
  OwaSettingsIconButton,
  OwaSecuredInput,
  IconButton,
};
