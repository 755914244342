import React, { useContext, useEffect, useState } from "react";
import Signature from "../../../Main/components/Signature";
import { connect } from "react-redux";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../../../reducers/progress/actions";
import Form from "../../../Main/components/Form";
import Button from "../../../../nekrasovka-ui/Button";
import { AlertContext } from "../../../../nekrasovka-ui/Alert/AlertProvider";
import {
  Container,
  MailTitle,
  SignatureForm,
  SignatureDescription,
  Input,
  OwaForm,
  OwaAuth,
  OwaMail,
  OwaCheckbox,
  Title,
  OwaSettings,
  OwaHeader,
  OwaMain,
  OwaMailStats,
  OwaMailData,
  OwaSettingsIconButton,
  OwaSecuredInput,
  IconButton,
} from "./mail.styles";
import { copyToClipboard, useIsMobile } from "../../../../helpers";
import Icon from "../../../../nekrasovka-ui/Icon";
import Tooltip from "../../../../nekrasovka-ui/Tooltip";
import Pagination from "./Pagination";

const Mail = ({
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
  signature,
  setSignature,
  initialSignature,
  getSignature,
  tokenParsed,
  patchMail,
  phoneFormater,
  originalSignature,
  countryCodes,
  phones,
  handlePhone,
  handleCountry,
  handleInputChange,
  countryCodeId,
  isPhone,
  setIsPhone,
  isCountry,
  setIsCountry,
  handleCopySignature,
  setCountryCodeId,
  initialCountryCodeId,
  handleCheckboxChange,
  getMessages,
  messages,
}) => {
  const { dispatch } = useContext(AlertContext);
  const [mailType, setMailType] = useState(1);
  const [sectionArrayNumber, setSectionArrayNumber] = useState([]);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useIsMobile(700);
  const itemsPerPage = 5;
  let timeoutPasswordVisible;

  // Вычисление индексов для текущей страницы
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleCheckbox = (event) => {
    if (signature.data.mosruMail && signature.data.mosruPass) {
      handleCheckboxChange(event);

      dispatch({
        type: "ALERT_ON",
        text: event.target.checked
          ? "Копирование писем включено"
          : "Копирование писем отключено",
        name: "info",
      });
    } else {
      dispatch({
        type: "ALERT_ON",
        text: "Не заданы «Ваш e-mail» и «Пароль». Обратитесь пожалуйста к администратору.",
        name: "warning",
      });
    }
  };

  const getMail = () => {
    clearProgress();
    startProgress();

    getSignature();
    getMessages();

    finishProgress();
    setTimeout(() => closeProgress(), 300);
  };

  const resetData = () => {
    setSignature(initialSignature);
    setCountryCodeId(initialCountryCodeId);
  };

  const handleCopy = async (text, name) => {
    const isCopied = await copyToClipboard(text);

    if (isCopied) {
      dispatch({
        type: "ALERT_ON",
        text: `Поле «${name}» скопировано`,
        name: "info",
      });
    }
  };

  const toggleSectionByNumber = (number) => {
    const index = sectionArrayNumber.indexOf(number);
    const array = [...sectionArrayNumber];

    if (index === -1) array.push(number);
    else array.splice(index, 1);

    setSectionArrayNumber(array);
  };

  const togglePasswordVisible = () => {
    setPasswordVisible(!isPasswordVisible);

    if (!isPasswordVisible) {
      clearTimeout(timeoutPasswordVisible);
      timeoutPasswordVisible = setTimeout(
        () => setPasswordVisible(false),
        60000,
      );
    }
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (isMobile) return;
    if (sectionArrayNumber.length > 0) {
      setSectionArrayNumber([]);
    }
  }, [isMobile]);

  useEffect(() => {
    if (
      signature.loaded &&
      signature.data.mosruMail &&
      signature.data.mosruPass
    ) {
      getMessages();
    }
  }, [signature]);

  useEffect(() => {
    getMail();

    return () => resetData();
  }, []);

  return (
    <Container>
      <MailTitle mailType={mailType}>
        <div onClick={() => mailType !== 1 && setMailType(1)}>
          Почта Некрасовки
        </div>
        <div onClick={() => mailType !== 2 && setMailType(2)}>
          Почта owa.mos.ru
        </div>
      </MailTitle>
      {signature.loaded && (
        <>
          {mailType === 1 && (
            <SignatureForm>
              <div>
                <SignatureDescription>
                  Корпоративная электронная почта Некрасовки, которая
                  используется для внутренней переписки и общения с
                  пользователями
                </SignatureDescription>
                <Button
                  onClick={() => window.open("https://mail2.nekrasovka.ru/")}
                  name="Открыть почту"
                />
              </div>
              <div>
                <Title>Подпись в письмах</Title>
                <div>
                  <Form
                    signature={signature.data}
                    handleInputChange={handleInputChange}
                    from_name={tokenParsed.name}
                    isPhone={isPhone}
                    setIsPhone={setIsPhone}
                    isCountry={isCountry}
                    setIsCountry={setIsCountry}
                    phones={phones}
                    countryCodes={countryCodes}
                    countryCodeId={countryCodeId}
                    handlePhone={handlePhone}
                    handleCountry={handleCountry}
                  />
                  <Signature
                    signature={signature.data}
                    from_name={tokenParsed.name}
                    from_addr={tokenParsed.email}
                    patchMail={patchMail}
                    getMail={getMail}
                    phoneFormater={phoneFormater}
                    isChanged={originalSignature.isChanged}
                    alternativePhoneCountryCode={
                      countryCodes[countryCodeId].code
                    }
                    handleCopySignature={handleCopySignature}
                  />
                </div>
              </div>
            </SignatureForm>
          )}
          {mailType === 2 && (
            <OwaForm>
              <OwaHeader>
                <SignatureDescription>
                  Корпоративная электронная почта owa.mos.ru
                </SignatureDescription>
                <form
                  action="https://owa.mos.ru/owa/auth.owa"
                  method="POST"
                  encType="application/x-www-form-urlencoded"
                  autoComplete="off"
                  target="_blank"
                >
                  <input
                    type="hidden"
                    name="destination"
                    value="https://owa.mos.ru/owa/"
                  />
                  <input type="hidden" name="flags" value="4" />
                  <input type="hidden" name="forcedownlevel" value="0" />
                  <input
                    type="hidden"
                    name="username"
                    value={signature.data.mosruMail.replace(/@.*/, "")}
                  />
                  <input
                    type="hidden"
                    name="password"
                    value={signature.data.mosruPass}
                  />
                  <input type="hidden" name="isUtf8" value="1" />
                  <Button type="submit" name="Открыть почту" />
                </form>
              </OwaHeader>
              <OwaMain>
                <OwaMail>
                  <div>
                    <div>
                      <Title>Письма</Title>
                      <OwaMailStats>
                        <div>
                          <span>Все</span>
                          <span>{messages.data.length}</span>
                        </div>
                        <div>
                          <span>Непрочитанные</span>
                          <span>0</span>
                        </div>
                      </OwaMailStats>
                      <OwaSettingsIconButton
                        isToggled={sectionArrayNumber.includes(1)}
                        onClick={() => toggleSectionByNumber(1)}
                      >
                        <Icon icon="arrowDown" height={23} fill="#777777" />
                      </OwaSettingsIconButton>
                    </div>
                    {!sectionArrayNumber.includes(1) && (
                      <OwaMailData>
                        <div>
                          {messages.data
                            .slice(indexOfFirstItem, indexOfLastItem)
                            .map(({ envelope }) => {
                              return (
                                <div key={envelope.messageId}>
                                  <div>
                                    <span>{envelope.from[0].name}</span>
                                    <span>{envelope.date}</span>
                                  </div>
                                  <div>
                                    <span>{envelope.subject}</span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <Pagination
                          totalPages={messages.totalPages}
                          currentPage={currentPage}
                          onPageChange={onPageChange}
                        />
                      </OwaMailData>
                    )}
                  </div>
                </OwaMail>
                <OwaSettings isToggled={sectionArrayNumber.includes(2)}>
                  <div>
                    <Title>Настройки</Title>
                    <OwaSettingsIconButton
                      isToggled={sectionArrayNumber.includes(2)}
                      onClick={() => toggleSectionByNumber(2)}
                    >
                      <Icon icon="arrowDown" height={23} fill="#777777" />
                    </OwaSettingsIconButton>
                  </div>
                  {!sectionArrayNumber.includes(2) && (
                    <>
                      <OwaCheckbox>
                        <label htmlFor="checkbox">
                          Копировать письма с mos.ru в мой ящик{" "}
                          {tokenParsed.email}
                        </label>
                        <input
                          type="checkbox"
                          name="mosruSync"
                          checked={signature.data.mosruSync}
                          onChange={handleCheckbox}
                        />
                        <span />
                      </OwaCheckbox>
                      <OwaAuth>
                        <div>
                          <label htmlFor="mosruMail">Ваш e-mail</label>
                          <Input
                            readOnly
                            type="text"
                            id="mosruMail"
                            name="mosruMail"
                            placeholder={signature.data.mosruMail}
                          />
                          <div>
                            <Tooltip text="скопировать">
                              <IconButton
                                onClick={() =>
                                  handleCopy(
                                    signature.data.mosruMail,
                                    "Ваш e-mail",
                                  )
                                }
                              >
                                <Icon icon="copy" height={15} fill="#777777" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="mosruPass">Пароль</label>
                          <OwaSecuredInput
                            readOnly
                            type="text"
                            id="mosruPass"
                            name="mosruPass"
                            placeholder={signature.data.mosruPass}
                            isPasswordVisible={isPasswordVisible}
                          />
                          <div>
                            <Tooltip
                              text={isPasswordVisible ? "скрыть" : "показать"}
                            >
                              <IconButton onClick={togglePasswordVisible}>
                                <Icon
                                  icon={isPasswordVisible ? "eye" : "eyeClosed"}
                                  height={15}
                                  fill="#777777"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip text="скопировать">
                              <IconButton
                                onClick={() =>
                                  handleCopy(signature.data.mosruPass, "Пароль")
                                }
                              >
                                <Icon icon="copy" height={15} fill="#777777" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </OwaAuth>
                    </>
                  )}
                </OwaSettings>
              </OwaMain>
            </OwaForm>
          )}
        </>
      )}
    </Container>
  );
};

export default connect(null, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(Mail);
