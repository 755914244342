import React from "react";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

const PagesContainer = styled.div`
  display: flex;
  column-gap: 5px;
`;

const PageButton = styled.button`
  padding: 5px 10px;
  border: none;

  &:not(:disabled) {
    cursor: pointer;
    background: ${({ active }) => (active ? "#777777" : "#ffffff")};
    color: ${({ active }) => (active ? "#fff" : "#777777")};
  }

  &:disabled {
    visibility: hidden;
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      background: #777777;
      color: #fff;
    }
  }
`;

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <PageButton
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </PageButton>,
      );
    }
    return pageNumbers;
  };

  return (
    <PaginationContainer>
      <PagesContainer>
        {/* Кнопка "В начало" */}
        <PageButton
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          {"<<"}
        </PageButton>

        {/* Кнопка перехода на предыдущую страницу */}
        <PageButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </PageButton>
      </PagesContainer>
      <PagesContainer>
        {/* Номера страниц */}
        {renderPageNumbers()}
      </PagesContainer>
      <PagesContainer>
        {/* Кнопка перехода на следующую страницу */}
        <PageButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </PageButton>

        {/* Кнопка "В конец" */}
        <PageButton
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          {">>"}
        </PageButton>
      </PagesContainer>
    </PaginationContainer>
  );
};

export default Pagination;
